<nb-layout xmlns:color="http://www.w3.org/1999/xhtml">

  <nb-layout-column>

    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>

    <form [formGroup]="VerifCodeForm">
      <tr>
        <img src="assets/images/logo/Manajero-logo.png" alt="" style="width: 40%; margin-left: 120px; " />
      </tr>
      <tr>
        <h4 style="color: #fcfcfc; margin-left: 75px; margin-top: 10px; ">Verification Code</h4>
      </tr>

          <div class="content">
            <div class="code-inputs">
              <input #input0 id="input0" type="text" maxlength="1" (keyup)="onKeyup($event, 0)" [readonly]="false">
              <input #input1 id="input1" type="text" maxlength="1" (keyup)="onKeyup($event, 1)" [readonly]="true">
              <input #input2 id="input2" type="text" maxlength="1" (keyup)="onKeyup($event, 2)" [readonly]="true">
              <input #input3 id="input3" type="text" maxlength="1" (keyup)="onKeyup($event, 3)" [readonly]="true">
              <input #input4 id="input4" type="text" maxlength="1" (keyup)="onKeyup($event, 4)" [readonly]="true">
              <input #input5 id="input5" type="text" maxlength="1" (keyup)="onKeyup($event, 5)" [readonly]="true">
            </div>
            <div class="new-code-section">
              <a class="new-code-link">Expired Code ?</a>
            </div>
          </div>

      <div style="align-self: flex-end; width: 100%; text-align: center; margin-top: 20px;" >
        <a  (click)="regeneraterCode()" href="javascript:void(0);" class="new-code-link" style="margin-bottom: 10px; text-decoration: underline; color: #3c80d9; font-weight: bold;">Get a new one</a>
      </div>
    </form>
  </nb-layout-column>
</nb-layout>