import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ProfileUserComponent} from './modules/profile-user/profile-user.component';
import {HomePageComponent} from './@theme/components/home-page/home-page.component';
import {AuthGuard} from './auth-guard/auth.guard';
import {Page404Component} from './@theme/components/page404/page404.component';
import {
  ConfirmationMailProfileComponent,
} from './modules/profile-user/confirmation-mail-profile/confirmation-mail-profile.component';
import { AccountingLogsListComponent } from './modules/Monitoring-management/components/logs/AccountingLogs/accounting-logs-list/accounting-logs-list.component';
import { CommunicationLogsListComponent } from './modules/Monitoring-management/components/logs/CommunicationLogs/communication-logs-list/communication-logs-list.component';
import { CrmLogsListComponent } from './modules/Monitoring-management/components/logs/CRMLogs/crm-logs-list/crm-logs-list.component';
import { FinanceLogsListComponent } from './modules/Monitoring-management/components/logs/FinanceLogs/finance-logs-list/finance-logs-list.component';
import { HrLogsListComponent } from './modules/Monitoring-management/components/logs/HrLogs/hr-logs-list/hr-logs-list.component';
import { MarketingLogsListComponent } from './modules/Monitoring-management/components/logs/MarketingLogs/marketing-logs-list/marketing-logs-list.component';
import { ProjectLogsListComponent } from './modules/Monitoring-management/components/logs/ProjectManagementLogs/project-logs-list/project-logs-list.component';
import { RecoveryLogsListComponent } from './modules/Monitoring-management/components/logs/RecoveryLogs/recovery-logs-list/recovery-logs-list.component';
import { StockLogsListComponent } from './modules/Monitoring-management/components/logs/StockLogs/stock-logs-list/stock-logs-list.component';
import { OverviewLogsListComponent } from './modules/Monitoring-management/components/overview/overview-logs-list/overview-logs-list.component';
import { SystemStateListComponent } from './modules/Monitoring-management/components/systemState/system-state-list/system-state-list.component';
import { AlertsListComponent } from './modules/Monitoring-management/components/alerts/alerts-list/alerts-list.component';
import { AccessControlListComponent } from './modules/Monitoring-management/components/accessControl/access-control-list/access-control-list.component';
import { AuditListComponent } from './modules/Monitoring-management/components/audit/audit-list/audit-list.component';
import { ActivityLogsListComponent } from './modules/Monitoring-management/components/activityLogs/activity-logs-list/activity-logs-list.component';
import { ArchLogsComponent } from './modules/Monitoring-management/components/activityLogs/archived-logs/arch-logs/arch-logs.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
//import { SessionExpirationComponent } from './modules/Security-management/session-expiration/session-expiration.component';
//import { ProductInventoryManagementModule } from './modules/product-inventory-management/product-inventory-management.module';
export const routes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
    //canActivate: [AuthGuard], // Add guard to login route
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'administration', loadChildren: () =>
      import('./modules/administration-management/administration.module')
        .then(m => m.AdministrationModule),
  },
 
   {
    path: 'hr',
    loadChildren: () =>
      import(
        './modules/humain-capital-management/humain-capital-management.module')
        .then((m) => m.HumainCapitalManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation-mail',
    component: ConfirmationMailProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'AccountingLogs',
    component: AccountingLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CommunicationLogs',
    component: CommunicationLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CRMLogs',
    component: CrmLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'FinanceLogs',
    component: FinanceLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'HRLogs',
    component: HrLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'MarketingLogs',
    component: MarketingLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ProjectManagementLogs',
    component: ProjectLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'RecoveryLogs',
    component: RecoveryLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'StockLogs',
    component: StockLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Overview',
    component: OverviewLogsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'systemState',
    component: SystemStateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'alerts',
    component: AlertsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activity-logs-list',
    component: ActivityLogsListComponent,
    canActivate: [AuthGuard],
  },
   {
     path: 'archived-logs',
     component: ArchLogsComponent,
     canActivate: [AuthGuard],
   },

  {
    path: 'accessControl',
    component: AccessControlListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'audit',
    component: AuditListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard],
  },
  {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {path: '**', pathMatch: 'full', component: HomePageComponent},
];

const config: ExtraOptions = { 
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
