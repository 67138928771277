<nb-card>
  <nb-card-header>
    <div class="text-center">
      <h4>Session Logs</h4> 
    </div>
    <div class="header-container">
      <div class="text-left  d-flex flex-row" *ngIf="!flippedState">
        <nb-select
          placeholder="All Levels"
          [(ngModel)]="value"
          (selectedChange)="selectedMethod()"
          (ngModelChange)="selectedMethod()"
          
        >
          <nb-option value="all levels">All Levels</nb-option>
          <nb-option value="WARN">WARN</nb-option>
          <nb-option value="INFO">INFO</nb-option>
          <nb-option value="ERROR">ERROR</nb-option>
        </nb-select>
        <div class="d-flex justify-content-center">
          <button
          nbButton
          ghost
          (click)="fetchLogs()"
        >
        <i class="fa-solid fa-rotate fa-xl"></i>
        </button>
        </div>
        
      </div>
      <div class="text-right" *ngIf="!flippedState">
        <button
          nbButton
          status="primary"
          shape="rectangle"
          (click)="exportAsXLSX()"
        >
          <i class="fas fa-file-export text-white"></i> Export Data
        </button>
        
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <nb-flip-card [flipped]="flippedState" [showToggleButton]="false">
      <nb-card-front>
        <nb-card>
          <ng2-smart-table
            (userRowSelect)="onLogRowSelect($event)"
            [settings]="settings"
            [source]="source"
            class="table-wrapper"
          ></ng2-smart-table>
        </nb-card>
      </nb-card-front>

      <nb-card-back *ngIf="flippedState">
        <nb-card>
          <nb-card-header>
            <button
              (click)="flipBack()"
              class="back-button"
              [ngClass]="{ 'back-button-clicked': flippedState }"
              nbButton
              status="primary"
            >
              <nb-icon
                aria-hidden="true"
                icon="chevron-left-outline"
                pack="nebular-essentials"
              ></nb-icon>
            </button>
          </nb-card-header>

          <nb-card-body>
            <ngx-activity-logs-details
              *ngIf="selectedLog !== null && selectedLog !== undefined"
              [selectedLog]="selectedLog"
            ></ngx-activity-logs-details>
            <!-- Add a message if selectedLog is null or undefined -->
            <div *ngIf="selectedLog === null || selectedLog === undefined">
              No log selected
            </div>
          </nb-card-body>
        </nb-card>
      </nb-card-back>
    </nb-flip-card>
  </nb-card-body>
</nb-card>
