import { Component, OnInit } from '@angular/core'; 
import { Chart ,ChartTypeRegistry} from 'chart.js/auto';
import { HttpClient } from '@angular/common/http';
import { logs } from 'app/modules/Monitoring-management/models/logs';
import { LogsService } from 'app/modules/Monitoring-management/services/logs.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-overview-logs-list',
  templateUrl: './overview-logs-list.component.html',
  styleUrls: ['./overview-logs-list.component.scss']
})
export class OverviewLogsListComponent implements OnInit {
  constructor(private http: HttpClient, private logs: LogsService) {}

  LogEntry: logs[] = [];
  emailList: string[] = [];
  roleList: string []= [];
  browserList : string [] = [];
  selectedEmail: string = '';
  selectedRole: string='';
  selectedBrowser: string = ''; // Holds the selected browser
  selectedMonthlyBrowser: string = '';
  todaysIncidents: number = 0;  
  todaysLogins : number = 0;
  todaysSus : number =0;
  unauthorizedAccessCount: number = 0;
  charts: { [key in keyof ChartTypeRegistry]?: Chart<key> } = {};
  private readonly THRESHOLD_INCIDENTS_MODERATE = 3;
  private readonly THRESHOLD_UNAUTHORIZED_MODERATE = 3;
  systemHealth: string = 'Stable';


  ngOnInit() {
    this.fetchLogs();
    this.loadTodaysIncidents();
    this.fetchTodaysLogins();
    this.fetchTodaysSuspicious();
    this.fetchUnauthorizedAccessCount();
    this.emailList = this.getTopEmails(5);
    setTimeout(() => {
      this.updateSystemHealth(); // Call this method after a short delay
  }, 1000);
  }
  fetchAllData(): void {
    forkJoin([
        this.loadTodaysIncidents(),
        this.fetchTodaysLogins(),
        this.fetchTodaysSuspicious(),
        this.fetchUnauthorizedAccessCount()
    ]).subscribe(() => {
        this.updateSystemHealth(); // Call this after all data is loaded
    });
}

  fetchUnauthorizedAccessCount(): void {
    const currentDate = new Date().toISOString().split('T')[0]; // This should return 'YYYY-MM-DD'

    this.logs.getUnauthorizedAccessCount(currentDate).subscribe({
        next: (count) => {
            this.unauthorizedAccessCount = count;
        },
        error: (error) => {
            console.error('Error fetching unauthorized access count', error);
        }
    });
}

  loadTodaysIncidents(): void {
    this.logs.getTodaysIncidents().subscribe({
      next: (data) => {
        this.todaysIncidents = data;
      },
      error: (err) => {
        console.error('Error fetching today\'s incidents', err);
      }
    });
  }

  fetchTodaysLogins(): void {
    this.logs.getTodaysLogins().subscribe(
      (count) => {
        this.todaysLogins = count;
      },
      (error) => {
        console.error('Error fetching today\'s logins', error);
      }
    );
  }
  fetchTodaysSuspicious() : void {
    this.logs.getSuspiciousActivityCount().subscribe(
      (count) => {
        this.todaysSus=count;
      },
      (error)=> {
        console.error('Eror fetching todays suspicious',error);
      }
    );
  }
  private updateSystemHealth(): void {

    // Check for Critical State
    if (this.todaysIncidents > this.THRESHOLD_INCIDENTS_MODERATE || 
        this.unauthorizedAccessCount > this.THRESHOLD_UNAUTHORIZED_MODERATE) {
        this.systemHealth = 'Critical';
    } 
    else {
        this.systemHealth = 'Stable';
    }

}



  fetchLogs() {
    this.http.get<logs[]>('https://gateway.manajero.com/api/auth/analyticsLogs').subscribe(
      (data) => {
        this.LogEntry = data;
        this.emailList = this.getUniqueEmails();
        this.roleList = this.getUniqueRoles();
        this.browserList = this.getUniqueBrowsers();
        this.createCharts();
        this.createLogLevelChart();
      },
      (error) => {
        console.error('Error fetching logs:', error);
      }
    );
  }

  getUniqueEmails(): string[] {
    const emailSet = new Set(this.LogEntry.map(log => log.userName));
    return Array.from(emailSet).sort();
  }
  getUniqueBrowsers(): string []{
    const browserSet=new Set(this.LogEntry.map(log=> log.browser));
    return Array.from(browserSet).sort();
  }
  getUniqueRoles(): string []{
    const roleSet=new Set(this.LogEntry.map(log=> log.role));
    return Array.from(roleSet).sort();
  }

  createCharts() {
    this.createWeeklyLoginChart();
    this.createMonthlyLoginChart();
    this.createWeeklyBrowserChart();  // Now generates pie chart
    this.createMonthlyBrowserChart(); // Now generates pie chart
    this.createEmailLoginChart();
    this.createLogLevelChart();
  }

  getTopEmails(count: number): string[] {
    const emailCounts = this.countLoginsByEmail(this.LogEntry);
    return Array.from(emailCounts.keys()).slice(0, count); // Return top N emails
  }
  
  onEmailSelect() {
    this.createEmailLoginChart(); // Re-create the chart based on the selected email
  }
  OnRoleSelect(){
    this.createLogLevelChart();
  }
  onBrowserSelect() {
    this.createWeeklyBrowserChart(); // Update chart when a browser is selected
  }
  onMonthlyBrowserSelect() {
    this.createMonthlyBrowserChart(); // Update chart on selection
}
  
  aggregateLogsByRoleAndLevel(logs: logs[]): Map<string, Map<string, number>> {
    const aggregatedData = new Map<string, Map<string, number>>();

    logs.forEach(log => {
        const role = log.role;
        const logLevel = log.level;

        if (!aggregatedData.has(role)) {
            aggregatedData.set(role, new Map<string, number>());
        }

        const levelCounts = aggregatedData.get(role)!;
        levelCounts.set(logLevel, (levelCounts.get(logLevel) || 0) + 1);
    });

    return aggregatedData;
}


createLogLevelChart() {
  const filteredLogs = this.selectedRole ? 
      this.LogEntry.filter(log => log.role === this.selectedRole) : 
      this.LogEntry;
  const aggregatedData = this.countLogsByRole(filteredLogs); // Get counts by role and log level

  const chartData = {
      labels: ['INFO', 'WARN', 'ERROR'], // Log levels
      datasets: []
  };

  aggregatedData.forEach((levelCounts, role) => {
      chartData.datasets.push({
          label: role,
          data: [
              levelCounts.get('INFO') || 0,
              levelCounts.get('WARN') || 0,
              levelCounts.get('ERROR') || 0,
          ],
          backgroundColor: this.getRandomColor(), // Set a function to generate unique colors for each role
      });
  });

  const ctx = document.getElementById('logLevelChart') as HTMLCanvasElement;

  if (this.charts['logLevelChart']) {
      this.charts['logLevelChart'].destroy(); // Destroy previous chart instance
  }

  this.charts['logLevelChart'] = new Chart(ctx, {
      type: 'bar', // You can also try 'horizontalBar' for a different orientation
      data: chartData,
      options: {
          responsive: true,
          plugins: {
              title: {
                  display: true,
                  text: 'Log Levels by Role',
              },
              legend: {
                  display: false, // Hide legend to reduce clutter
              },
          },
          scales: {
              x: {
                  stacked: true, // Stack the bars
                  title: {
                      display: true,
                      text: 'Log Levels',
                  },
                  ticks: {
                      font: {
                          size: 12, // Adjust font size for x-axis labels
                      }
                  }
              },
              y: {
                  stacked: true,
                  beginAtZero: true,
                  title: {
                      display: true,
                      text: this.selectedRole ? `Logins for ${this.selectedRole}` : 'Logins by Role',
                  },
                  ticks: {
                      font: {
                          size: 12, // Adjust font size for y-axis labels
                      }
                  }
              }
          }
      }
  });
}

  
  createEmailLoginChart() {
    const filteredLogs = this.selectedEmail ? 
      this.LogEntry.filter(log => log.userName === this.selectedEmail) : 
      this.LogEntry;
  
    const emailCounts = this.countLoginsByEmail(filteredLogs);
  
    if (this.charts['emailLoginChart']) {
      this.charts['emailLoginChart'].destroy();
    }
  
    const ctx = document.getElementById('emailLoginChart') as HTMLCanvasElement;
  
    this.charts['emailLoginChart'] = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Array.from(emailCounts.keys()),
        datasets: [{
          label: 'Number of Logins',
          data: Array.from(emailCounts.values()),
          backgroundColor: Array.from(emailCounts.keys()).map(() => this.getRandomColor()),
        }],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: this.selectedEmail ? `Logins for ${this.selectedEmail}` : 'Logins by Email',
          }
        },
        scales: {
          x: { beginAtZero: true },
        }
      }
    });
  }
  countLoginsByEmail(logs: logs[]): Map<string, number> {
    const emailCounts = new Map<string, number>();
    logs.forEach(log => {
      emailCounts.set(log.userName, (emailCounts.get(log.userName) || 0) + 1);
    });
    return new Map([...emailCounts.entries()].sort((a, b) => b[1] - a[1]));
  }
  countLogsByRole(logs: logs[]): Map<string, Map<string, number>> {
    const roleCounts = new Map<string, Map<string, number>>();

    logs.forEach(log => {
        // Get or create the inner Map for the role
        if (!roleCounts.has(log.role)) {
            roleCounts.set(log.role, new Map<string, number>([
                ['INFO', 0],
                ['WARN', 0],
                ['ERROR', 0]
            ]));
        }

        // Get the inner Map for the specific role
        const levelCounts = roleCounts.get(log.role)!;

        // Increment the count for the specific log level
        if (log.level) {
            levelCounts.set(log.level, (levelCounts.get(log.level) || 0) + 1);
        }
    });

    return roleCounts;
}
countLogsByBrowser(logs: logs[]): Map<string, number> {
  const browserCounts = new Map<string,number>();

  logs.forEach(log => {
      // Get or create the inner Map for the role
      browserCounts.set(log.browser,(browserCounts.get(log.browser)|| 0)+1);
});
return new Map([...browserCounts.entries()].sort((a,b)=> b[1]-a[1]));
      }    
     

  createWeeklyLoginChart() {
    const weeklyCounts = this.aggregateByWeek(this.LogEntry);
    this.createTimeSeriesChart('weeklyLoginChart', 'Weekly Logins', weeklyCounts);
  }

  createMonthlyLoginChart() {
    const monthlyCounts = this.aggregateByMonth(this.LogEntry);
    this.createTimeSeriesChart('monthlyLoginChart', 'Monthly Logins', monthlyCounts);
  }

  createWeeklyBrowserChart() {
    const filteredLogs = this.selectedBrowser 
      ? this.LogEntry.filter(log => log.browser === this.selectedBrowser) 
      : this.LogEntry;
  
    // Aggregate browser counts by week
    const weeklyBrowserCounts = this.aggregateByWeekAndBrowser(filteredLogs);
    
    // Prepare data for the chart
    const browsers = new Set<string>();
    const dataForChart: any = { labels: [], datasets: [] };
  
    weeklyBrowserCounts.forEach((browserData, week) => {
      dataForChart.labels.push(week);
      browserData.forEach((count, browser) => {
        browsers.add(browser);
      });
    });
  
    browsers.forEach(browser => {
      const dataset = {
        label: browser,
        data: [],
        backgroundColor: this.getRandomColor(), // Generate random color for each browser
      };
  
      weeklyBrowserCounts.forEach((browserData, week) => {
        dataset.data.push(browserData.get(browser) || 0); // Push 0 if no data for that browser in the week
      });
  
      dataForChart.datasets.push(dataset);
    });
  
    // Get the context of the canvas element for the chart
    const ctx = document.getElementById('weeklyBrowserChart') as HTMLCanvasElement;
  
    // Destroy previous instance of the chart if it exists
    if (this.charts['weeklyBrowserChart']) {
      this.charts['weeklyBrowserChart'].destroy();
    }
  
    // Create the new chart
    this.charts['weeklyBrowserChart'] = new Chart(ctx, {
      type: 'bar', // You can also use 'line' for a different representation
      data: dataForChart,
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: this.selectedBrowser ? `Weekly Logins for ${this.selectedBrowser}` : 'Weekly Browser Usage',
          },
          legend: {
            display: false,
            position: 'top',
          }
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: 'Week'
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: 'Logins'
            }
          }
        }
      }
    });
  }
  
  

  // createMonthlyBrowserChart() {
  //   const monthlyBrowserCounts = this.aggregateByMonthAndBrowser(this.LogEntry);
  //   const browsers = new Set<string>();
  //   const dataForChart: any = { labels: [], datasets: [] };
  
  //   // Iterate over the monthly aggregated data
  //   monthlyBrowserCounts.forEach((browserCounts, monthKey) => {
  //     dataForChart.labels.push(monthKey); // Add month as a label
  //     browserCounts.forEach((count, browser) => {
  //       browsers.add(browser); // Collect all browser types
  //       // Check if the dataset for this browser already exists
  //       if (!dataForChart.datasets.some((dataset: any) => dataset.label === browser)) {
  //         dataForChart.datasets.push({
  //           label: browser,
  //           data: Array(dataForChart.labels.length - 1).fill(0), // Initialize data for previous months
  //           backgroundColor: this.getRandomColor(),
  //         });
  //       }
  //       const dataset = dataForChart.datasets.find((d: any) => d.label === browser);
  //       dataset.data.push(count); // Add the count for this month
  //     });
  //   });
  
  //   // Ensure all datasets have the same number of data points
  //   dataForChart.datasets.forEach((dataset: any) => {
  //     if (dataset.data.length < dataForChart.labels.length) {
  //       dataset.data.push(0); // Fill missing data with 0 for incomplete months
  //     }
  //   });
  
  //   // Destroy previous chart instance if it exists
  //   if (this.charts['monthlyBrowserChart']) {
  //     this.charts['monthlyBrowserChart'].destroy();
  //   }
  
  //   // Create the chart with new data
  //   const ctx = document.getElementById('monthlyBrowserChart') as HTMLCanvasElement;
  //   this.charts['monthlyBrowserChart'] = new Chart(ctx, {
  //     type: 'bar',
  //     data: dataForChart,
  //     options: {
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       plugins: {
  //         title: {
  //           display: true,
  //           text: 'Monthly Browser Usage',
  //         }
  //       },
  //       scales: {
  //         x: { stacked: true }, // Stack the bars horizontally
  //         y: { stacked: true, beginAtZero: true }, // Stack the bars vertically and start at 0
  //       },
  //     }
  //   });
  // }
  createMonthlyBrowserChart() {
    // Filter logs based on the selected browser if provided
    const filteredLogs = this.selectedMonthlyBrowser 
      ? this.LogEntry.filter(log => log.browser === this.selectedMonthlyBrowser) 
      : this.LogEntry;

    // Aggregate browser counts by month
    const monthlyBrowserCounts = this.aggregateByMonthAndBrowser(filteredLogs);
    
    // Prepare data for the chart
    const browsers = new Set<string>();
    const dataForChart: any = { labels: [], datasets: [] };

    // Fill labels and collect browsers
    monthlyBrowserCounts.forEach((browserData, month) => {
        dataForChart.labels.push(month); // Add month as a label
        browserData.forEach((count, browser) => {
            browsers.add(browser); // Collect all browser types
        });
    });

    // Prepare datasets for each browser
    browsers.forEach(browser => {
        const dataset = {
            label: browser,
            data: [],
            backgroundColor: this.getRandomColor(), // Generate random color for each browser
        };

        monthlyBrowserCounts.forEach((browserData, month) => {
            dataset.data.push(browserData.get(browser) || 0); // Push count or 0 if no data for that browser
        });

        dataForChart.datasets.push(dataset);
    });

    // Get the context of the canvas element for the chart
    const ctx = document.getElementById('monthlyBrowserChart') as HTMLCanvasElement;

    // Destroy previous instance of the chart if it exists
    if (this.charts['monthlyBrowserChart']) {
        this.charts['monthlyBrowserChart'].destroy();
    }

    // Create the new chart
    this.charts['monthlyBrowserChart'] = new Chart(ctx, {
        type: 'bar', // You can also use 'line' for a different representation
        data: dataForChart,
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: this.selectedMonthlyBrowser 
                          ? `Monthly Logins for ${this.selectedMonthlyBrowser}` 
                          : 'Monthly Browser Usage',
                },
                legend: {
                    display: false, // Disable the legend since we have the dropdown
                    position: 'top',
                },
            },
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Month',
                    },
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Logins',
                    },
                },
            },
        },
    });
}




  

  aggregateByWeek(logs: logs[]): Map<string, number> {
    const weeklyCounts = new Map<string, number>();
    logs.forEach(log => {
      const date = new Date(log.timestamp);
      const weekKey = this.getWeekKey(date);
      weeklyCounts.set(weekKey, (weeklyCounts.get(weekKey) || 0) + 1);
    });
    return new Map([...weeklyCounts.entries()].sort());
  }

  aggregateByMonth(logs: logs[]): Map<string, number> {
    const monthlyCounts = new Map<string, number>();
    logs.forEach(log => {
      const date = new Date(log.timestamp);
      const monthKey = this.getMonthKey(date);
      monthlyCounts.set(monthKey, (monthlyCounts.get(monthKey) || 0) + 1);
    });
    return new Map([...monthlyCounts.entries()].sort());
  }

  aggregateByWeekAndBrowser(logs: logs[]): Map<string, Map<string, number>> {
    const weeklyBrowserCounts = new Map<string, Map<string, number>>();
    logs.forEach(log => {
      const date = new Date(log.timestamp);
      const weekKey = this.getWeekKey(date);
      if (!weeklyBrowserCounts.has(weekKey)) {
        weeklyBrowserCounts.set(weekKey, new Map<string, number>());
      }
      const browserCounts = weeklyBrowserCounts.get(weekKey)!;
      browserCounts.set(log.browser, (browserCounts.get(log.browser) || 0) + 1);
    });
    return new Map([...weeklyBrowserCounts.entries()].sort());
  }

  aggregateByMonthAndBrowser(logs: logs[]): Map<string, Map<string, number>> {
    const monthlyBrowserCounts = new Map<string, Map<string, number>>();
    logs.forEach(log => {
      const date = new Date(log.timestamp);
      const monthKey = this.getMonthKey(date);
      if (!monthlyBrowserCounts.has(monthKey)) {
        monthlyBrowserCounts.set(monthKey, new Map<string, number>());
      }
      const browserCounts = monthlyBrowserCounts.get(monthKey)!;
      browserCounts.set(log.browser, (browserCounts.get(log.browser) || 0) + 1);
    });
    return new Map([...monthlyBrowserCounts.entries()].sort());
  }

  aggregateBrowserData(browserCounts: Map<string, Map<string, number>>): Map<string, number> {
    const aggregatedData = new Map<string, number>();
    browserCounts.forEach((counts) => {
      counts.forEach((count, browser) => {
        aggregatedData.set(browser, (aggregatedData.get(browser) || 0) + count);
      });
    });
    return aggregatedData;
  }

  getWeekKey(date: Date): string {
    const year = date.getFullYear();
    const weekNumber = this.getWeekNumber(date);
    return `${year}-W${weekNumber.toString().padStart(2, '0')}`;
  }

  getMonthKey(date: Date): string {
    return date.toISOString().slice(0, 7);
  }

  getWeekNumber(date: Date): number {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  }

  getRandomColor(): string {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  }

  createTimeSeriesChart(canvasId: string, title: string, data: Map<string, number>) {
    new Chart(canvasId, {
      type: 'line',
      data: {
        labels: Array.from(data.keys()),
        datasets: [{
          label: 'Logins',
          data: Array.from(data.values()),
          borderColor: '#36A2EB',
          fill: false
        }]
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time Period'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Number of Logins'
            },
            beginAtZero: true
          }
        }
      }
    });
  }
}

