<nb-card>
  <nb-card-header>
    <div class="text-center">
      <h4>Accounting Logs</h4>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <div class="text-left  d-flex flex-row">
          <nb-select class="me-2" placeholder="All Methods" [(ngModel)]="value" (selectedChange)="selectedMethod()" (ngModelChange)="selectedMethod()" >
            <nb-option value="All Methods">All Methods</nb-option>
            <nb-option value="POST">POST</nb-option>
            <nb-option value="GET">GET</nb-option>
            <nb-option value="PUT">PUT</nb-option>
            <nb-option value="DELETE">DELETE</nb-option>
          </nb-select>
          <div style="display: inline-block; margin-right: 20px;" ></div>
          <nb-select placeholder="All Status" [(ngModel)]="values" (selectedChange)="selectedValues()" (ngModelChange)="selectedValues()"class="me-2">
            <nb-option value="All Status">All Status</nb-option>
            <nb-option value="200">200</nb-option>
            <nb-option value="500">500</nb-option>
          </nb-select>
          <div class="d-flex justify-content-center" >
            <button
            nbButton
            ghost
            (click)="getAccLogs()"
          >
          <i class="fa-solid fa-rotate fa-xl"></i>
          </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-primary" style="box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); border-radius: 5px;" (click)="exportAsXLSX()">
          <i class="fas fa-file-export text-white"></i> Export Data
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="AccounSettings" [source]="source" class="table-wrapper"></ng2-smart-table>
  </nb-card-body>
</nb-card>
