import { Component, Input, OnInit } from '@angular/core';
import { NbThemeService , NbAccordionModule} from '@nebular/theme';
import { ArchiveService } from '../../services/archive.service';
import { logs } from 'app/modules/Monitoring-management/models/logs';

@Component({
  selector: 'ngx-archived-logs-details',
  templateUrl: './archived-logs-details.component.html',
  styleUrls: ['./archived-logs-details.component.scss']
})
export class ArchivedLogsDetailsComponent implements OnInit {
  @Input() selectedLog: logs;
  flippedState: boolean = false;
  @Input() currentLog: logs;
  logsModel: logs[]; // Added semicolon
  data: any; // Changed data type to any
  logProperties: { label: string, value: string }[] = [];

  constructor(private archiveService : ArchiveService ,private themeService: NbThemeService){

  }
  ngOnInit(): void {
    this.logProperties = [
      { label: 'Logger', value: this.selectedLog?.logger || '-' },
      { label: 'Browser', value: this.selectedLog?.browser || '-' },
      { label: 'Username', value: this.selectedLog?.userName || '-' },
      { label: 'Device', value: this.selectedLog?.device || '-' },
      { label: 'Thread', value: this.selectedLog?.thread || '-' },
      { label: 'Level', value: this.selectedLog?.level || '-' },
      { label: 'Time', value: this.formatTimestamp(this.selectedLog?.timestamp) || '-' },
      { label: 'Message', value: this.selectedLog?.message || '-' },
      { label: 'IP Address', value: this.selectedLog?.ipAddress || '-' },
      { label: 'Session ID', value: this.selectedLog?.sessionId || '-' },
      { label: 'Role', value: this.formatRole(this.selectedLog?.role) || '-' },
      { label: 'Operating System', value: this.selectedLog?.operatingSystem || '-'},
      { label: 'Location',value : this.selectedLog?.location || '-'}

    ];
  }
  formatRole(role: string): string {
    if (!role) {
      return role;
    }

    // Split the string by underscores, capitalize the first letter of each word, and join them with spaces
    return role.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  getPropertyValue(label: string): string {
    const property = this.logProperties.find(prop => prop.label === label);
    return property ? property.value : '-';
  }

  formatTimestamp(timestamp: Date): string {
    if (!timestamp) return ''; // Return empty string if timestamp is not provided
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  }


Display(): void {
 // console.log(this.selectedLog, "+", this.currentLog);
}

flip(): void {
  this.flippedState = !this.flippedState;
}

getAlertColor(level: string): string {
  if (level === "WARN") {
    return "warning";
  } else if (level === "ERROR") {
    return "danger";
  } else if (level === "INFO") {
    return "success";
  } else {
    return "secondary"; // Default color if none of the conditions match
  }
}

}
