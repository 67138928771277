<div class="alerts-container">
    <h1><i class="fas fa-shield-alt"></i> Security Alerts</h1>
    <nb-card>
      <nb-card-body>
        <div class="table-actions">
          <input type="text" placeholder="Search alerts..." (input)="filterAlerts($event.target.value)" />
          <button nbButton outline status="info" (click)="toggleSortDirection()">
            {{ sortDirection === 'asc' ? 'Sort Ascending' : 'Sort Descending' }} 
            <i [class]="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
          </button>
          <button nbButton status="primary" (click)="exportAlerts()">Export CSV</button>
        </div>
        <table class="alerts-table">
          <thead>
            <tr>
              <th><i class="fas fa-clock"></i> Timestamp</th>
              <th><i class="fas fa-exclamation-triangle"></i> Message</th>
              <th><i class="fas fa-user"></i> User</th>
              <th><i class="fas fa-info-circle"></i> Severity</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let alert of pagedAlerts" [ngClass]="{'high-severity': alert.level === 'HIGH', 'medium-severity': alert.level === 'MEDIUM','low-severity': alert.level === 'LOW'}" (click)="showAlert(alert)">
              <td>{{ alert.timestamp | date:'short' }}</td>
              <td>{{ alert.message }}</td>
              <td>{{ alert.username }}</td>
              <td><span [ngClass]="alert.level">{{ alert.level }}</span></td>
            </tr>
          </tbody>
        </table>
  
        <!-- Paginator -->
        <mat-paginator [length]="filteredAlerts.length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="onPageChange($event)">
        </mat-paginator>
      </nb-card-body>
    </nb-card>
  </div>
  