import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecurityAlert } from '../models/SecurityAlert';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private apiUrl = 'https://gateway.manajero.com/api/alert/alerts' ;
  private baseUrl = 'https://gateway.manajero.com/api/alert/todays-incidents';
  private url='https://gateway.manajero.com/api/auth/todays-logins';
  private susCount = 'https://gateway.manajero.com/api/auth/count-today';
  private unauthUrl= 'https://gateway.manajero.com';


  constructor(private http: HttpClient) {}
  getAlerts(): Observable<SecurityAlert[]> {
    return this.http.get<SecurityAlert[]>(this.apiUrl);
  }
  getTodaysIncidents(): Observable<number> {
    return this.http.get<number>(this.baseUrl);
  }
  getTodaysLogins(): Observable<number>{
    return this.http.get<number>(this.url);
  }
  getSuspiciousActivityCount(): Observable<number> {
    return this.http.get<number>(this.susCount);
  }
  getUnauthorizedAccessCount(date: string): Observable<number> {
    return this.http.get<number>(`${this.unauthUrl}/api/alert/unauthorized-access-count`, {
      params: { date }
    });
  }

  
 
}