<div *ngIf="selectedLog"  [class.dark-mode]="currentTheme === 'dark'">
    <!-- <nb-accordion>
        <nb-accordion-item [expanded]="true">
          <nb-accordion-item-header><div class="text-center"><h4></h4></div></nb-accordion-item-header>
          <nb-accordion-item-body>
            <div *ngFor="let property of logProperties">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">{{ property.label }}</p>
                </div>
                <div class="col-sm-9 font-weight-bold"> 
                  {{ property.value }}
                </div>
              </div>
              <hr>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion> -->
  </div>
  
  <nb-card-body>
    <nb-alert accent="info">
      <div class="row">
        <h6
          class="text-center"
          style="margin-bottom: 5%"
          class="col-md-6 offset-md-4"
        >
        Session Details:
        </h6>
      </div>
  
      <div class="container row">
        <div class="col-md-6">
          <p *ngIf="getPropertyValue('Logger')">
            <strong class="text-secondary">
              <nb-icon icon="file-text-outline" status="info"></nb-icon>
              Logger :
            </strong>
            {{ getPropertyValue("Logger") }}
          </p>
  
          <p *ngIf="getPropertyValue('Browser')">
            <strong class="text-secondary">
              <nb-icon icon="globe-outline" status="info"></nb-icon>
              Browser :
            </strong>
            {{ getPropertyValue("Browser") }}
          </p>
  
          <p *ngIf="getPropertyValue('Username')">
            <strong class="text-secondary">
              <nb-icon icon="person-outline" status="info"></nb-icon>
              Username :
            </strong>
            {{ getPropertyValue("Username") }}
          </p>
          <p *ngIf="getPropertyValue('Device')">
            <strong class="text-secondary">
              <nb-icon icon="smartphone-outline" status="info"></nb-icon>
              Device :
            </strong>
            {{ getPropertyValue("Device") }}
          </p>
          <p *ngIf="getPropertyValue('Thread')">
            <strong class="text-secondary">
              <nb-icon icon="link-2-outline" status="info"></nb-icon>
              Thread :
            </strong>
            {{ getPropertyValue("Thread") }}
          </p>
  
          <p *ngIf="getPropertyValue('Level')">
            <strong class="text-secondary">
              <nb-icon icon="bar-chart-outline" status="info"></nb-icon>
              Level :
            </strong>
            <ng-container [ngSwitch]="getPropertyValue('Level')">
              <nb-tag
                *ngSwitchCase="'ERROR'"
                status="danger"
                appearance="outline"
                text="Error"
              ></nb-tag>
              <nb-tag
                *ngSwitchCase="'WARNING'"
                status="warning"
                appearance="outline"
                text="Warning"
              ></nb-tag>
              <nb-tag
                *ngSwitchCase="'INFO'"
                status="info"
                appearance="outline"
                text="Info"
              ></nb-tag>
              <nb-tag
                *ngSwitchDefault
                status="basic"
                appearance="outline"
                text="{{ getPropertyValue('Level') }}"
              ></nb-tag>
            </ng-container>
          </p>
        </div>
        <div class="col-md-6">
          <p *ngIf="getPropertyValue('Time')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="calendar-outline"></nb-icon>
              Time :
            </strong>
            {{ getPropertyValue("Time") }}
          </p>
          <p *ngIf="getPropertyValue('Message')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="message-circle-outline"></nb-icon>
              Message :
            </strong>
            {{ getPropertyValue("Message") }}
          </p>
          <p *ngIf="getPropertyValue('IP Address')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="wifi-outline"></nb-icon>
              IP Address :
            </strong>
            {{ getPropertyValue("IP Address") }}
          </p>
          <p *ngIf="getPropertyValue('Session ID')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="hash-outline"></nb-icon>
              Session ID :
            </strong>
            {{ getPropertyValue("Session ID") }}
          </p>
          <p *ngIf="getPropertyValue('Operating System')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="monitor-outline"></nb-icon>
              Operating System :
            </strong>
            {{ getPropertyValue("Operating System") }}
          </p>
          <p *ngIf="getPropertyValue('Role')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="shield-outline"></nb-icon>
              Role :
            </strong>
            {{ getPropertyValue("Role") }}
          </p>
          <p *ngIf="getPropertyValue('Location')">
            <strong class="text-secondary"
              ><nb-icon status="info" icon="compass-outline"></nb-icon>
              Location :
            </strong>
            {{ getPropertyValue("Location") }}
          </p>
        </div>
      </div>
    </nb-alert>
  </nb-card-body>

  


