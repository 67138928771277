<div class="overview-container">
  <div class="header-metrics">
    <!-- Security-related Metrics -->
    <nb-card class="metric-card"> 
      <div class="metric-content">
        <span>Today's Incidents</span>
        <h3>{{todaysIncidents}}</h3>
      </div>
    </nb-card>
    <nb-card class="metric-card"> 
      <div class="metric-content">
        <span>Today's Logins</span>
        <h3>{{todaysLogins}}</h3>
      </div>
    </nb-card>
    <nb-card class="metric-card"> 
      <div class="metric-content">
        <span>Today's Suspicious Activities</span>
        <h3>{{todaysSus}}</h3>
      </div>
    </nb-card>
    <nb-card class="metric-card"> 
      <div class="metric-content">
        <span>Unauthorized Access Attempts</span>
        <h3>{{unauthorizedAccessCount}}</h3>
      </div>
    </nb-card>
    <nb-card class="metric-card"> 
      <div class="metric-content">
        <span class="system-health-label">System Health</span>
        <h3 [ngClass]="{
                'system-health-status': true,
                'stable' : systemHealth === 'Stable',
                'critical': systemHealth === 'Critical'
            }">
            {{ systemHealth }}
        </h3>
    </div>
    
    </nb-card>
</div>


  <!-- Charts section -->
  <div class="charts-section">
    <div class="chart-row">
      <nb-card>
        <div class="chart-container">
          <canvas id="monthlyLoginChart"></canvas>
        </div>
      </nb-card>
      <nb-card>
        <div class="chart-container">
          <canvas id="weeklyLoginChart"></canvas>
        </div>
      </nb-card>
    </div>

    <div class="chart-row">
      <nb-card >
        <div class="chart-container">
        <div></div>
        <nb-select placeholder="Select Browser" [(selected)]="selectedMonthlyBrowser" (selectedChange)="onMonthlyBrowserSelect()">
          <nb-option value="">All Browsers</nb-option>
          <nb-option *ngFor="let browser of browserList" [value]="browser">{{ browser }}</nb-option>
        </nb-select>
        </div>
          <canvas id="monthlyBrowserChart"></canvas>
      </nb-card>
      <nb-card>
        <div class="chart-container">
          <div></div> <!-- Empty div for spacing or additional content if needed -->
          <nb-select placeholder="Select Browser" [(selected)]="selectedBrowser" (selectedChange)="onBrowserSelect()">
            <nb-option value="">All Browsers</nb-option>
            <nb-option *ngFor="let browser of browserList" [value]="browser">{{ browser }}</nb-option>
          </nb-select>
        </div>
        <canvas id="weeklyBrowserChart"></canvas>
      </nb-card>
      
    </div>

    <div class="chart-row">
      <nb-card>
        <div  class="chart-container">
          <div></div>
          <nb-select placeholder="Select Email" [(selected)]="selectedEmail" (selectedChange)="onEmailSelect()">
            <nb-option value="">All Emails</nb-option>
            <nb-option *ngFor="let email of emailList" [value]="email">{{ email }}</nb-option>
          </nb-select>
        </div>
        <canvas id="emailLoginChart"></canvas>
      </nb-card>
      <nb-card>
        <div class="chart-container">
            <div></div>
            <nb-select placeholder="Select Role" [(selected)]="selectedRole" (selectedChange)="OnRoleSelect()">
                <nb-option value="">All Roles</nb-option>
                <nb-option *ngFor="let role of roleList" [value]="role">{{ role }}</nb-option>
            </nb-select>
        </div>
        <canvas id="logLevelChart"></canvas>
    </nb-card>
    
    </div>
  </div>
</div>
