import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimer: any;
  private alertTimer: any;
  private logoutTime = 10 * 60 * 1000; // 10 minutes in milliseconds
  private warningTime = 9 * 60 * 1000; // 9 minutes to show alert before logging out
  private activityDetected = false; // Track whether activity has been detected

  constructor(private router: Router, private ngZone: NgZone, private dialog: MatDialog) {
    this.setupActivityListeners(); // Only start the timer after activity
  }

  public startInactivityTimer() {
    if (!this.activityDetected) {
      // Do not start the timer unless activity has been detected
      return;
    }

    // Inactivity timer for logout
    this.inactivityTimer = setTimeout(() => {
      this.ngZone.run(() => {
        if (!this.isOnLoginPage()) {
          // Navigate to login page after 10 minutes of inactivity
          this.router.navigate(['/auth/login']);
        }
      });
    }, this.logoutTime);
  
    // Show alert after 9 minutes of inactivity, keep for 1 minute
    this.alertTimer = setTimeout(() => {
      this.ngZone.run(() => {
        if (!this.isOnLoginPage()) {
          // Display the alert after 9 minutes of inactivity
          window.alert('You have been inactive for 10 minutes. You will be logged out in 1 minute.');
  
          // Automatically close alert after 1 minute
          setTimeout(() => {
            // This timeout is used for hiding the alert after 1 minute
            console.log('Closing alert after 1 minute');
          }, 60 * 1000); // 1 minute in milliseconds
        }
      });
    }, this.warningTime); // 9 minutes warning before logout
  }

  public resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.alertTimer); // Reset the alert timer as well
    this.startInactivityTimer();
  }

  public setupActivityListeners() {
    const resetOnActivity = () => {
      this.activityDetected = true; // Mark activity as detected
      this.resetInactivityTimer();
    };

    // Attach listeners for user activity
    window.addEventListener('mousemove', resetOnActivity);
    window.addEventListener('keydown', resetOnActivity);
    window.addEventListener('click', resetOnActivity);

    // You can add more listeners as needed, like touch events for mobile
  }

  private isOnLoginPage(): boolean {
    return this.router.url.includes('/auth/login');
  }
}
