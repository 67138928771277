<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>

<div class="container p-0">
  <h1 class="h3 mb-3">Profile</h1>
  <div class="row">
    <div class="col-md-5 col-xl-4">
      <nb-card>
        <nb-card-header class="d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0">
            General
          </h5>
        </nb-card-header>
        <nb-card-body>
          <div class="image-container">
            <img [nbSpinner]="loading" nbSpinnerStatus="danger" class="user-image" src="{{img}}" alt="">
          </div>
          <div class="user-info-container">
            <p *ngIf="userRole === 'ROLE_ADMIN'">
              <nb-tag status="success" appearance="filled" text="ADMIN"></nb-tag>
            </p>
          </div>
          <p>
            <nb-icon icon="email-outline"></nb-icon>
            {{user.email}}
          </p>
          <p *ngIf="user && userRole !=='ROLE_SUPER_ADMIN'">
            <nb-icon icon="phone-outline"></nb-icon>
            {{user.phone}}
          </p>
          <p *ngIf="user && user.company && userRole !=='ROLE_SUPER_ADMIN'">
            <nb-icon icon="globe-outline"></nb-icon>
            {{user?.company.city}}, {{user?.company.country}}
          </p>

        </nb-card-body>
        <!-- <div class="list-group list-group-flush" role="tablist">
            <a class="list-group-item list-group-item-action active"
               data-toggle="list" href="#account" role="tab">
              Account
            </a>
          <a class="list-group-item list-group-item-action" data-toggle="list" href="#password" role="tab">
              Password
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Privacy and safety
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Email notifications
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Web notifications
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Widgets
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Your data
            </a>
            <a class="list-group-item list-group-item-action" data-toggle="list" href="#" role="tab">
              Delete account
            </a>
        </div> -->
      </nb-card>
      <div class="d-flex justify-content-center" *ngIf="!hasUpdateAccess">
        <button type="button" class="btn btn-primary" (click)="SendMail()">
          <i class="fas fa-edit"></i> Update your informations
        </button>
      </div>
    </div>

    <div class="col-md-7 col-xl-8">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="account" role="tabpanel">

          <nb-card>
            <nb-card-header>
              <!--  <nb-action class="card-actions float-right">
                 <div class="dropdown show">
                   <a href="#" data-toggle="dropdown" data-display="static">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-more-horizontal align-middle">
                       <circle cx="12" cy="12" r="1"></circle>
                       <circle cx="19" cy="12" r="1"></circle>
                       <circle cx="5" cy="12" r="1"></circle>
                     </svg>
                   </a>

                   <div class="dropdown-menu dropdown-menu-right">
                     <a class="dropdown-item" href="#">Action</a>
                     <a class="dropdown-item" href="#">Another action</a>
                     <a class="dropdown-item" href="#">Something else here</a>
                   </div>
                 </div>
               </nb-action> -->
              <h5 class="card-title mb-0">User Data</h5>
            </nb-card-header>
            <nb-card-body>
              <form [formGroup]="userForm" (ngSubmit)="updateUser()">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="username">Username</label>
                      <input id="username" fieldSize="large" type="text" fullWidth nbInput placeholder="No data"
                             [formControlName]="'username'" [readOnly]="!hasUpdateAccess">
                      <div *ngIf="(username.invalid && username.touched)" class="col-lg-12">
                        <div *ngIf="username.errors?.required">
                          <p class="mt-2 text-danger">Username is required !</p>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="userProfileImage" >Profile Image</label>
                      <input autocomplete="off"
                             formControlName="userProfileImage"
                             (change)="toggleLoadingAnimation($event)"

                             fullWidth
                             id="userProfileImage"
                             name="userProfileImage"
                             nbInput
                             type="file"
                             [readOnly]="!hasUpdateAccess">

                    </div>
                    <label for="phone">Phone Number</label>
                    <div class="form-group">

                      <ngx-intl-tel-input
                        id="phone"
                        [preferredCountries]="preferredCountries"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectedCountryISO]="CountryISO.Tunisia"
                        [maxLength]="maxLengthNumber"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [selectFirstCountry]="false"
                        [searchCountryFlag]="true"
                        [phoneValidation]="true"
                        [separateDialCode]="true"
                        [numberFormat]="PhoneNumberFormat.National"
                        [formControlName]="'phone'"

                      >
                        >
                      </ngx-intl-tel-input>
                      <div *ngIf="(phone.invalid && phone.touched)" class="col-lg-12">
                        <div *ngIf="phone.errors?.required">
                          <p class="mt-2 text-danger">Phone Number is required !</p>
                        </div>
                        <div *ngIf="phone.errors && !phone.errors?.required">
                          <p class="mt-2 text-danger">Phone Number is not valid !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="Email">Email</label>
                      <input type="email" nbInput fullWidth fieldSize="large" id="Email"
                             placeholder="No data" [formControlName]="'email'" [readOnly]="!hasUpdateAccess">
                      <div *ngIf="(email.invalid && email.touched)" class="col-lg-12">
                        <div *ngIf="email.errors?.required">
                          <p class="mt-2 text-danger">Email is required !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="currentPassword">Password</label>
                      <div class="password-input d-flex align-items-center flex-row">
                        <input [type]="showPassword ? 'text' : 'password'" nbInput fullWidth fieldSize="large"
                               id="currentPassword"
                               placeholder="Enter your password" [formControlName]="'currentPassword'" [readOnly]="!hasUpdateAccess">
                        <span class="show-password-icon">
                          <i *ngIf="!showPassword" class="fa fa-eye" aria-hidden="true" (click)="toggleShowPassword()"
                          ></i>
                          <i *ngIf="showPassword" class="fa fa-eye-slash" aria-hidden="true" (click)="toggleShowPassword()"
                          ></i>
                        </span>
                      </div>
                      <div *ngIf="(currentPassword.invalid && currentPassword.touched) && hasUpdateAccess " class="col-lg-12">
                        <div *ngIf="currentPassword.errors?.required">
                          <p class="mt-2 text-danger">You need to enter your password to procced !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="newPassword">New Password</label>
                      <div class="password-input d-flex align-items-center flex-row">
                        <input [type]="shownewPassword ? 'text' : 'password'" nbInput fullWidth fieldSize="large" id="newPassword"
                               placeholder="Enter a new password" [formControlName]="'newPassword'" [readOnly]="!hasUpdateAccess">
                        <span class="show-password-icon">
                          <i *ngIf="!shownewPassword" class="fa fa-eye" aria-hidden="true" (click)="toggleShowNewPassword()"
                          ></i>
                          <i *ngIf="shownewPassword" class="fa fa-eye-slash" aria-hidden="true" (click)="toggleShowNewPassword()"
                          ></i>
                        </span>
                      </div>
                      <div *ngIf="userForm.hasError('samePassword')" class="col-lg-12">
                        <p class="mt-2 text-danger">New password must be different from the old password!</p>
                      </div>
                      <div *ngIf="(newPassword.invalid && newPassword.touched)" class="col-lg-12">
                        <div *ngIf="newPassword.errors?.pattern">
                          <p class="mt-2 text-danger">Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special characters and be at least 8 characters long !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="confirmPassword">Confirm Password</label>
                      <div class="password-input d-flex align-items-center flex-row">
                        <input [type]="showcfPassword ? 'text' : 'password'" nbInput fullWidth fieldSize="large" id="confirmPassword"
                               placeholder="Confirm your password" [formControlName]="'confirmPassword'" [readOnly]="!hasUpdateAccess">
                        <span class="show-password-icon">
                          <i *ngIf="!showcfPassword" class="fa fa-eye" aria-hidden="true" (click)="toggleShowCfPassword()"
                          ></i>
                          <i *ngIf="showcfPassword" class="fa fa-eye-slash" aria-hidden="true" (click)="toggleShowCfPassword()"
                          ></i>
                        </span>
                      </div>
                      <div *ngIf="(confirmPassword.invalid && confirmPassword.touched)" class="col-lg-12">
                        <div *ngIf="confirmPassword.errors?.confirmedValidator">
                          <p class="mt-2 text-danger">Passwords must match !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <nb-checkbox (checkedChange)="toggle($event)">Stay connected</nb-checkbox>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary"
                        [disabled]="userForm.invalid || confirmPassword.errors?.confirmedValidator || !hasUpdateAccess">
                  Save changes
                </button>

              </form>

            </nb-card-body>
          </nb-card>

          <nb-card *ngIf="userRole !=='ROLE_SUPER_ADMIN'">
            <nb-card-header>
              <div class="card-actions float-right">
                <div class="dropdown show">

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </div>
              </div>
              <h5 class="card-title mb-0">Employee Data</h5>
            </nb-card-header>
            <nb-card-body>
              <form [formGroup]="employeeForm" (ngSubmit)="updateEmployee()">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="employeeName">Name</label>
                      <input id = "employeeName" type="text" nbInput fullWidth fieldSize="large"
                             placeholder="No data" [formControlName]="'employeeName'" [readOnly]="!hasUpdateAccess">
                      <div *ngIf="(employeeName.invalid && employeeName.touched)" class="col-lg-12">
                        <div *ngIf="employeeName.errors?.required">
                          <p class="mt-2 text-danger">Name is required !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="employeeCellPhone">Phone Number</label>
                      <div>
                        <ngx-intl-tel-input
                          id="employeeCellPhone"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectedCountryISO]="CountryISO.Tunisia"
                          [maxLength]="maxLengthNumber"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [selectFirstCountry]="false"
                          [searchCountryFlag]="true"
                          [phoneValidation]="true"
                          [separateDialCode]="true"
                          [numberFormat]="PhoneNumberFormat.National"
                          [formControlName]="'employeeCellPhone'">
                        </ngx-intl-tel-input>
                      </div>
                      <div *ngIf="(employeeCellPhone.invalid && employeeCellPhone.touched)" class="col-lg-12">
                        <div *ngIf="employeeCellPhone.errors?.required">
                          <p class="mt-2 text-danger">Phone Number is required !</p>
                        </div>
                        <div *ngIf="employeeCellPhone.errors && !employeeCellPhone.errors?.required">
                          <p class="mt-2 text-danger">Phone Number is not valid !</p>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class=" col-md-6 form-group">
                        <label for="employeecountry">Country</label>
                        <nb-select
                          id="employeecountry"
                          [formControlName]="'employeeCountry'"
                          [ngStyle]="{'width':'100%'}"
                          (selectedChange)="getCountryStates()"
                          placeholder="{{employee.employeeCountry}}"
                        >
                          <nb-option *ngFor="let country of countryList" [value]="country">
                            {{ country.name }}
                          </nb-option>
                        </nb-select>

                        <div *ngIf="(employeeCountry.invalid && employeeCountry.touched)" class="col-lg-12">
                          <div *ngIf="employeeCountry.errors?.required">
                            <p class="mt-2 text-danger">Country is required !</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 form-group">
                        <label for="employeeCity">City</label>
                        <nb-select
                          id="employeeCity"
                          [formControlName]="'employeeCity'"
                          [ngStyle]="{'width':'100%'}"
                          placeholder="{{employee.employeeCity}}"
                        >
                          <nb-option *ngFor="let city of citiesList" [value]="city.name">
                            {{ city.name }}
                          </nb-option>
                        </nb-select>
                        <div *ngIf="(employeeCity.invalid && employeeCity.touched)" class="col-lg-12">
                          <div *ngIf="employeeCity.errors?.required">
                            <p class="mt-2 text-danger">City is required !</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <div class="text-center">
                      <!--                      <img alt="Andrew Jones"-->
                      <!--                           src="https://bootdey.com/img/Content/avatar/avatar1.png"-->
                      <!--                           class="rounded-circle img-responsive mt-2" width="128" height="128">-->
                      <img [src]="employeeForm.get('employeeProfileImage').value" alt="Profile Image"
                           class="rounded-circle img-responsive mt-2" width="128" height="128">
                      <div class="mt-2" *ngIf="hasUpdateAccess">
                        <label for="fileInput" class="upload-label">
                          <input id="fileInput" type="file" (change)="onFileSelected($event)">
                          <i class="fa fa-upload"></i>
                        </label>
                      </div>
                      <small></small>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary"
                        [disabled]="employeeForm.invalid || !hasUpdateAccess">
                  Save changes
                </button>
              </form>

            </nb-card-body>
          </nb-card>

        </div>
      </div>
    </div>
  </div>

</div>