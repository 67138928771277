import { Injectable } from '@angular/core';
import {EnryptionService} from '../../../Security-management/services/enryption.service'
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USERID_KEY = 'userId';
const USERROLE_KEY = 'userrole';
const EMAIL_KEY = 'user-email'; // Add a key for email
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private encryptionService: EnryptionService) { }
  
  public signOut(): void {
    window.localStorage.clear();  // or sessionStorage.clear() if using session storage
  }
  
  public saveToken(token: string): void {

    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }
  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }
  public saveUserEmail(email: string): void { // New method to save email
    const encryptedEmail = this.encryptionService.encrypt(email);
    window.localStorage.removeItem(EMAIL_KEY);
    window.localStorage.setItem(EMAIL_KEY, encryptedEmail);
  }
  public saveUser(user: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(user));
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, encryptedUser);
  }
  public userId(userId: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(userId));
    window.localStorage.removeItem(USERID_KEY);
    window.localStorage.setItem(USERID_KEY, encryptedUser);
  } 
  public userrole(userrole: any): void {
    const encryptedUser = this.encryptionService.encrypt(JSON.stringify(userrole));
    window.localStorage.removeItem(USERROLE_KEY);
    window.localStorage.setItem(USERROLE_KEY, encryptedUser);
  }
  public getUserId(): any {
    const encryptedUserId=window.localStorage.getItem(USERID_KEY)
    if (encryptedUserId){
      try {
        const decryptedUseId = this.encryptionService.decrypt(encryptedUserId);
        return JSON.parse(decryptedUseId)
      } catch (error){
        console.error('Error decrypting user id:', error);
        return {};
      }
    }
    return {};
  }

  // Get the user from local storage with decryption
  public getUser(): any {
    const encryptedUser = window.localStorage.getItem(USER_KEY);
    if (encryptedUser) {
      try {
        const decryptedUser = this.encryptionService.decrypt(encryptedUser);
        return JSON.parse(decryptedUser);
      } catch (error) {
        console.error('Error decrypting user:', error);
        return {};
      }
    }
    return {};
  }
  // Get the userrole from local storage with decryption
  public getUserRole(): any {
    const encryptedUser = window.localStorage.getItem(USERROLE_KEY);
    if (encryptedUser) {
      try {
        const decryptedUser = this.encryptionService.decrypt(encryptedUser);
        return JSON.parse(decryptedUser);
      } catch (error) {
        console.error('Error decrypting user:', error);
        return {};
      }
    }
    return {};
  }
  public getUserEmail(): string | null { // New method to get email
    const encryptedEmail = window.localStorage.getItem(EMAIL_KEY);
    if (encryptedEmail) {
      try {
        return this.encryptionService.decrypt(encryptedEmail);
      } catch (error) {
        console.error('Error decrypting email:', error);
        return null;
      }
    }
    return null;
  }
  
  public clearData() {
    localStorage.clear();
  }

 
}
